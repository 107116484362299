import { useRuntimeConfig } from '#imports';
import * as Sentry from '@sentry/nuxt';

const sentryDSN = useRuntimeConfig().public.sentry.dsn
const sentryEnv = useRuntimeConfig().public.sentry.environment
if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    environment: sentryEnv || 'development',
  })
} else {
  console.warn('[sentry] DSN not set, skipping client Sentry initialization')
}
